import React from "react";
import styled from "styled-components";
import Section from "./section";
import media from "../styles/media";

const TwoColumnText = ({ title, text, html }) => {
  return (
    <Layout>
      <Section>
        <StyledGridWrapp>
          {title && <StyledTitle>{title}</StyledTitle>}
          {text && <StyledText>{text}</StyledText>}
          {html && (
            <StyledInnerHTML
              dangerouslySetInnerHTML={{ __html: html }}
            ></StyledInnerHTML>
          )}
        </StyledGridWrapp>
      </Section>
    </Layout>
  );
};

export default TwoColumnText;

const Layout = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const StyledGridWrapp = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 30px;
  ${media.phablet`display: block;`};
`;

const StyledTitle = styled.div`
  grid-column: 2 / 5;
  font-style: normal;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 100.1%;
  ${media.bigDesktop`grid-column: 1 / 5;  font-size: 1.75rem;`};
  ${media.desktop`grid-column: 1 / 5;`};
  ${media.tablet`font-size: 1.5rem;`};
  ${media.thone`font-size: 1.3rem;`};
  ${media.phablet`padding-bottom: 14px;`};
`;

const StyledText = styled.div`
  grid-column: 6 / -1;
  font-style: normal;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 136.6%;
  ${media.bigDesktop`font-size: 1.2rem; grid-column: 5 / -1;`};
  ${media.desktop`font-size: 1.1rem;`};
  ${media.tablet`font-size: 1rem;`};
`;

const StyledInnerHTML = styled.div`
  grid-column: 6 / -1;
  font-style: normal;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 136.6%;
  ${media.bigDesktop`font-size: 1.2rem; grid-column: 5 / -1;`};
  ${media.desktop`font-size: 1.1rem;`};
  ${media.tablet`font-size: 1rem;`};
  strong {
    font-weight: 500;
  }
  p {
    margin-block-start: 0em;
  }
`;
