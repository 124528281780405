import React from "react";
import AnimationLink from "../../components/animation-link";
import styled from "styled-components";
import Section from "../../components/section";
import DarkTurquoiseArrowIcon from "../../components/icons/dark-turquoise-arrow-icon";
import media from "../../styles/media";
import theme from "../../styles/theme";
const { colors } = theme;

const ServiceHero = (props) => {
  const { title, link, pageLayoutRef } = props;
  return (
    <Layout>
      <Section>
        <AnimationLink navLink={`/`} pageLayoutRef={pageLayoutRef}>
          <MenuLogo>
            <LogoText>belo.</LogoText>
          </MenuLogo>
        </AnimationLink>
        <StyledContainer>
          <StyledGridWrapp>
            {title && (
              <StyledWrappTitle>
                <StyledTitle>{title}</StyledTitle>
                {link && link.link.type === "external-link" && (
                  <StyledExternalLink
                    href={link.link.externalLink}
                    target="_blank"
                  >
                    {link.name}
                    <DarkTurquoiseArrowIcon />
                  </StyledExternalLink>
                )}
              </StyledWrappTitle>
            )}
          </StyledGridWrapp>
        </StyledContainer>
      </Section>
    </Layout>
  );
};

export default ServiceHero;

const Layout = styled.div`
  position: relative;
`;

const MenuLogo = styled.div`
  position: relative;
  top: 40px;
  left: 0px;
  width: 99px;
  height: 39px;
  z-index: 999;
`;

const LogoText = styled.div`
  letter-spacing: -0.5px;
  font-size: 2.7rem;
`;

const StyledContainer = styled.div`
  width: 100%;
  padding-bottom: 64px;
`;

const StyledGridWrapp = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 30px;
  ${media.phone`gap: 0px 0px;`};
`;

const StyledWrappTitle = styled.div`
  margin-top: 129px;
  grid-column: 2 / -1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${media.desktop`grid-column: 1 / -1;`};
  ${media.thone`display: block;`};
`;

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 3rem;
  line-height: 108.1%;
  max-width: 500px;
  ${media.bigDesktop`font-size: 3rem;`};
  ${media.desktop`font-size: 2.5rem; max-width: 400px;`};
  ${media.tablet`font-size: 2rem; max-width: 250px;`};
`;

const StyledExternalLink = styled.a`
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 108.1%;
  color: ${colors.darkTurquoise};
  transform: translateY(-10px);
  display: flex;
  align-items: center;
  svg {
    margin-left: 30px;
    margin-right: 12px;
    ${media.desktop`margin-left: 12px; height: 30px;`};
    ${media.tablet`margin-left: 8px; height: 24px;`};
  }

  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.41, 0, 0.019, 1);
  &:hover {
    svg {
      transform: translateX(10px);
      transition-duration: 0.3s;
    }
  }
  ${media.bigDesktop`font-size: 1.8rem;`};
  ${media.desktop`font-size: 1.5rem;`};
  ${media.tablet`font-size: 1.3rem;`};
  ${media.thone`margin-top: 32px; transform: translateY(0px); float: right;`};
`;
