import React, { useRef } from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import ServiceHero from "../modules/hero/service-hero";
import ModuleFooter from "../modules/module-footer";
import TwoColumnText from "../components/two-column-text";
import FeaturedWork from "../components/featured-work";

const ServiceTemplate = ({ location, data }) => {
  const {
    serviceData: { seo, title, overview, ourProcess, featuredWork, link, footer },
  } = data;
  const pageLayoutRef = useRef();
  return (
    <div ref={pageLayoutRef}>
      <SEO data={seo} location={location}/>
      <Layout location={location}>
        <ServiceHero title={title} link={link} pageLayoutRef={pageLayoutRef} />
        <TwoColumnText title="Overview" text={overview.overview} />
        <TwoColumnText
          title="Our Process"
          html={ourProcess.childMarkdownRemark.html}
        />
        <FeaturedWork
          title="Featured Work"
          featuredWork={featuredWork}
          link={link}
          pageLayoutRef={pageLayoutRef}
        />
        <ModuleFooter
          {...footer}
          location={location}
          pageLayoutRef={pageLayoutRef}
        />
      </Layout>
    </div>
  );
};

export default ServiceTemplate;

export const ServiceTemplateQuery = graphql`
  query($contentfulId: String!) {
    serviceData: contentfulModuleService(contentful_id: { eq: $contentfulId }) {
      name
      slug
      seo {
        ...GlobalSEOFragment
      }
      title
      overview {
        overview
      }
      ourProcess {
        ourProcess
        childMarkdownRemark {
          html
        }
      }
      featuredWork {
        name
        title
        slug
        illustrations {
          title
          fluid(
            maxWidth: 800
            quality: 95
            toFormat: JPG
            cropFocus: FACES
            resizingBehavior: FILL
          ) {
            ...GatsbyContentfulFluid
          }
        }
      }
      link {
        ...GlobalItemFragment
      }
      footer {
        ...ModuleFooterFragment
      }
    }
  }
`;
