import React from "react";
import Img from "gatsby-image";
import AnimationLink from "./animation-link";
import styled from "styled-components";
import Section from "../components/section";
import DarkTurquoiseArrowIcon from "./icons/dark-turquoise-arrow-icon";
import media from "../styles/media";
import theme from "../styles/theme";
const { colors } = theme;

const FeaturedWork = (props) => {
  const { title, featuredWork, link, pageLayoutRef } = props;

  const size = 2;
  const splitIntoChunkArray = featuredWork.reduce((acc, curr, i) => {
    if (!(i % size)) {
      acc.push(featuredWork.slice(i, i + size));
    }
    return acc;
  }, []);

  return (
    <Section>
      <StyledContainer>
        <ContentWrapp>
          <StyledTitle>{title}</StyledTitle>
        </ContentWrapp>
        {splitIntoChunkArray.map((arr, index) => {
          return (
            <StyledImgWrapp key={index}>
              <StyledProjectImgWrapp hoverHandle={arr[0]}>
                <AnimationLink
                  navLink={`/projects/${arr[0].slug}`}
                  pageLayoutRef={pageLayoutRef}
                >
                  <Img
                    key="first-project-1"
                    fluid={arr[0].illustrations[0].fluid}
                    title={arr[0].title}
                  />
                  <StyledProjectTitle className="first-project-title">
                    {arr[0].title}
                  </StyledProjectTitle>
                </AnimationLink>
              </StyledProjectImgWrapp>
              <StyledProjectImgWrapp hoverHandle={arr[1]}>
                {arr.length === 2 && (
                  <AnimationLink
                    navLink={`/projects/${arr[1].slug}`}
                    pageLayoutRef={pageLayoutRef}
                  >
                    <Img
                      key="first-project-2"
                      fluid={arr[1].illustrations[0].fluid}
                      title={arr[1].title}
                    />
                    <StyledProjectTitle>{arr[1].title}</StyledProjectTitle>
                  </AnimationLink>
                )}
              </StyledProjectImgWrapp>
            </StyledImgWrapp>
          );
        })}
      </StyledContainer>
      <StyledButtonWrapp>
        {link && link.link.type === "external-link" && (
          <StyledExternalLink href={link.link.externalLink} target="_blank">
            {link.name}
            <DarkTurquoiseArrowIcon />
          </StyledExternalLink>
        )}
      </StyledButtonWrapp>
    </Section>
  );
};

export default FeaturedWork;

const StyledContainer = styled.div`
  padding-top: 72px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 30px;
  ${media.tablet`gap: 0px 15px;`};
  ${media.thone`display: block; padding-top: 50px;`};
`;

const ContentWrapp = styled.div`
  width: 100%;
  grid-column: 2 / 5;
  ${media.desktop`grid-column: 1 / 5;`};
`;

const StyledImgWrapp = styled.div`
  grid-column: 6 / -1;
  display: flex;
  align-items: center;
  margin-bottom: 85px;
  div:first-child {
    margin-right: 33px;
    ${media.desktop`margin-right: 15px;`};
    ${media.phablet`margin-right: 0px;`};
  }
  ${media.bigDesktop`grid-column: 5 / -1;`};
  ${media.desktop`grid-column: 5 / -1;`};
  ${media.phablet`display: block;`};
  a {
    width: 100%;
  }
`;

const StyledProjectImgWrapp = styled.div`
  flex: 1;
  height: 100%;
  ${({ hoverHandle }) => hoverHandle && `cursor: pointer;`};
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.41, 0, 0.019, 1);
  &:hover {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.41, 0, 0.019, 1);
    transform: translateY(-3px);
  }
  .gatsby-image-wrapper {
    height: 100%;
    object-fit: cover;
    width: auto;
  }
  .first-project-title {
    ${media.phablet`margin-bottom: 41px;`};
  }
`;

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 100.1%;
  ${media.bigDesktop`font-size: 1.5rem;`};
  ${media.thone`font-size: 1.3rem; margin-bottom: 32px;`};
`;

const StyledProjectTitle = styled.div`
  margin-top: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 136.6%;
  ${media.tablet`font-size: 1rem;`};
`;

const StyledButtonWrapp = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledExternalLink = styled.a`
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 108.1%;
  color: ${colors.darkTurquoise};
  display: flex;
  align-items: center;
  margin-top: 32px;
  svg {
    margin-left: 30px;
    margin-right: 12px;
    ${media.desktop`margin-left: 12px; height: 30px;`};
    ${media.tablet`margin-left: 8px; height: 24px;`};
  }

  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.41, 0, 0.019, 1);
  &:hover {
    svg {
      transform: translateX(10px);
      transition-duration: 0.3s;
    }
  }
  ${media.bigDesktop`font-size: 1.8rem;`};
  ${media.desktop`font-size: 1.5rem;`};
  ${media.tablet`font-size: 1.3rem;`};
  ${media.thone` float: right; margin-bottom: 0px; margin-top: 0px;`};
`;
